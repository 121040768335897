.region {
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 100%;

  .container {
    height: 100%;
    line-height: 48px;
    display: flex;

    .button {
      color: #ffffff;
      background-color: #212121;
      border-width: 0;
      height: 100%;
      box-sizing: border-box;
      line-height: 48px;
      overflow: visible;
      display: block;
      position: relative;
      margin: 0;
      padding: 0;
      min-width: 48px;
      text-align: center;
      transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
      cursor: pointer;

      &:hover {
        box-shadow: initial;
        z-index: initial;
        color: #ffffff;
        background-color: #000000;
      }
    }
  }
}

.title {
  font: 600 22px SegoeUI-SemiBold-final, "Segoe UI Semibold",
    SegoeUI-Regular-final, "Segoe UI", "Segoe UI Web (West European)", Segoe,
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma,
    Helvetica, Arial, sans-serif;
  color: #484644;
  display: inline-block;
  margin: 30px 0 20px 18px;
}

.content {
  margin-left: 8px;
}

.item {
  float: left;
  width: 146px;
}

.link:hover {
  border-color: #f4f4f4 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transition: border-color 0.167s linear, box-shadow 0.167s linear;
}

.link {
  display: flex;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent !important;
  height: 48px;
  line-height: normal;
  text-decoration: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: border-color 83ms linear, box-shadow 83ms linear;
  color: #222222;
}

.link-icon {
  font-size: 26px;
  color: inherit;
  margin: 10px 10px 0 10px;
}

.link-name {
  font: 14px SegoeUI-Regular-final, "Segoe UI", "Segoe UI Web (West European)",
    Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma,
    Helvetica, Arial, sans-serif;
  color: #212121;
  margin-top: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  transition: color 83ms linear;
}

.panel-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.panel-collapse {
  background-color: transparent;
  box-sizing: border-box;
  border: 0;
  margin: 5px;
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  color: #000;
  transition: background-color 83ms linear;
}

.panel-collapse:hover {
  background-color: #dbdad7 !important;
  transition: background-color 0.167s linear;
}

.panel-header-link {
  display: flex;
  font-family: SegoeUI-SemiBold-final, "Segoe UI Semibold",
    SegoeUI-Regular-final, "Segoe UI", "Segoe UI Web (West European)", Segoe,
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma,
    Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  background: 0 0 !important;
  border: 0;
  padding: 0;
  min-width: 0;
  height: auto;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  top: 17px;
  left: 160px;
}
