.region {
  display: flex;
  flex: 1 0 auto;
  height: 100%;

  .brand-container {
    flex: 1 0 0;
    display: flex;
    height: 100%;
    justify-content: flex-start;

    .brand-information {
      height: 100%;
      line-height: 48px;
      display: flex;

      .app-name {
        height: 100%;
        display: inline-block;
        vertical-align: middle;
        outline-offset: -1px;
        padding: 0 20px 0 8px;
        color: #ffffff;
        box-sizing: initial;
        font-weight: 600;
        font-family: "SegoeUI-SemiBold-final", "Segoe UI Semibold",
          "SegoeUI-Regular-final", "Segoe UI", "Segoe UI Web (West European)",
          Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
          Tahoma, Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 48px;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  .search-container {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    justify-content: center;

    .search-box {
      height: 100%;
      line-height: 48px;
      display: flex;
      overflow: visible;
      -webkit-justify-content: center;
      justify-content: center;
      z-index: 3;
      flex: 1 0 auto;

      .fast-hero-search {
        background-color: #fff;
        display: -webkit-inline-flex;
        display: inline-flex;
        border-radius: 2px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        min-width: 390px;
        position: relative;
        height: 32px;
        margin: 8px;
        line-height: 36px;
        z-index: 1;
        max-width: 34.3vw;
        width: 100%;

        .search-form {
          position: relative;
          width: 100%;
          height: 100%;

          border-radius: 2px;
          display: -webkit-flex;
          display: flex;
          overflow: hidden;

          .search-input {
            width: 100%;
            border: none;
          }
        }
      }
    }
  }

  .icon-container {
    flex: 1 0 0;
    display: flex;
    height: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 639px) {
  .region {
    .search-container {
      display: none !important;
    }
  }
}
