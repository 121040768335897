* {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

#root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.full-flex {
  flex: 1;
  display: flex;
}

.full-height {
  height: 100%;
}

.flex-dir-column {
  flex-direction: column;
}

.full-width {
  width: 100%;
  box-sizing: border-box;
}

.ms-SearchBox {
  border-radius: 2px;
}

.logo-image {
  height: 80px;
}

.ms-Spinner-circle,
.ms-Spinner-label {
  color: #20343c !important;
  border-color: #20343c rgb(174, 182, 185) rgb(174, 182, 185) !important;
}
