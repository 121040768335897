.header {
  border-spacing: 0;
  display: flex;
  min-height: 50px;
  max-height: 50px;
  line-height: normal;
  white-space: nowrap;
  width: 100% !important;
  background-color: #212121;
  background-position: left top;
  background-repeat-x: repeat;
  background-image: none;
  position: fixed;
  z-index: 100;
}
