.region {
  display: flex;

  .container {
    height: 100%;
    display: flex;
    line-height: 48px;
  }

  .button {
    color: #ffffff;
    background-color: #212121;
    border-width: 0;
    height: 100%;
    box-sizing: border-box;
    line-height: 48px;
    overflow: visible;
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-width: 48px;
    text-align: center;
    transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
    cursor: pointer;
    outline-offset: -1px;

    .profiler {
      height: 100%;
      max-width: 176px;
      display: table;

      .name-container {
        text-align: right;
        vertical-align: middle;
        display: table-cell;
        overflow: hidden;
        max-width: 120px;
        font-size: 14px;
        font-family: "SegoeUI-SemiLight-final", "Segoe UI Semilight",
          "SegoeUI-Regular-final", "Segoe UI", "Segoe UI Web (West European)",
          Segoe, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
          Tahoma, Helvetica, Arial, sans-serif;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        margin-left: 4px;

        span {
          display: inline;
        }
      }

      .image-container {
        display: table-cell;
        vertical-align: middle;
        background: transparent;
        border-width: 0;
        height: 100%;
        box-sizing: border-box;
        line-height: 48px;
        overflow: visible;
        position: relative;
        margin: 0;
        padding: 0;
        min-width: 48px;
        text-align: center;
        -webkit-transition: background-color 0.467s
          cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
        transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
        cursor: pointer;
        outline-offset: -1px;

        .image-region {
          width: 32px;
          height: 32px;
          overflow: hidden;
          display: inline-block;
          border-radius: 50%;
          vertical-align: middle;
          font-size: 6px;

          .profile-content {
            width: 32px;
            height: 32px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;

            .short-name-container {
              background-color: rgb(185, 29, 71);
              font-size: 16px;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 50%;
              text-align: center;
              display: inline-block;
              height: 100%;
              width: 100%;
              color: #fff;
              font-family: SegoeUI-SemiLight-final, "Segoe UI Semilight",
                SegoeUI-Regular-final, "Segoe UI",
                "Segoe UI Web (West European)", Segoe, -apple-system,
                BlinkMacSystemFont, Roboto, "Helvetica Neue", Tahoma, Helvetica,
                Arial, sans-serif;
              line-height: 195%;
            }

            .image-wrapper {
              position: absolute;
              left: 0;
              top: 0;
              display: inline-block;
              width: 100%;
              height: 100%;
              line-height: normal;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 639px) {
  .region {
    .name-container {
      display: none !important;
    }
  }
}
